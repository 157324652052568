import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import './TemplateReport.css';
import SentMessagesGraph from '../Charts/SentMessagesGraph';
import SpendingChart from '../Charts/SpendingChart';
import ResponseTimeChart from '../Charts/ResponseTimeChart';
import LocationDistributionMap from '../Charts/LocationDistributionMap';
import CampaignComparisonChart from '../Charts/CampaignComparisonChart';

const possibleStatuses = ['read', 'failed',  'pending', 'delivered'];

const TemplateReport = () => {
    const location = useLocation();
    const { templateId } = location.state || {}; // Extract templateId from state
    const [reportData, setReportData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [statusResponse, setStatusResponse] = useState(null); // For storing status response
    const [statusError, setStatusError] = useState(null); // For storing status error

    // Define vibrant gradient colors for the cards
    const gradientColors = [
         // Purple to Blue
        'linear-gradient(90deg, #6496DB, #6496DB)',  // Yellow to Orange
        'linear-gradient(90deg, #FFCD56, #FFCD56)',  // Green to Light Green
        'linear-gradient(90deg, #FF6384, #FF6384 )',  // Blue gradient for delivered
        'linear-gradient(90deg, #13AA52, #00662B)',
        'linear-gradient(90deg, #6851BB, #5C4CB9)',
    ];

    useEffect(() => {
        const fetchReportData = async () => {
            if (!templateId) {
                setError("Template ID is missing.");
                setLoading(false);
                return;
            }

            const token = localStorage.getItem('token');

            try {
                const response = await axios.post(
                    `https://api.lohith.in:3007/api/v1/wa/report/${templateId}`,
                    {},
                    { headers: { Authorization: `Bearer ${token}` } }
                );

                console.log("Report Data:", response.data); // Log the data for inspection
                setReportData(response.data); // Set the report data
            } catch (error) {
                console.error('Error fetching report data:', error);
                setError(error.response?.data?.message || "Error fetching report data");
            } finally {
                setLoading(false);
            }
        };

        fetchReportData();

        // Set "read" as the default status selection
        handleCardClick('read');
    }, [templateId]);

    // Function to get count for each status
    const getStatusCount = (status) => {
        const message = reportData.messages.find(msg => msg.status === status);
        return message ? message.count : 0; // Return count or 0 if not found
    };

    // Handler for card click
    const handleCardClick = async (status) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(
                `https://api.lohith.in:3007/api/v1/wa/reportstatus/${templateId}?status=${status}&page=1&limit=10`,
                {},
                { headers: { Authorization: `Bearer ${token}` } }
            );
            console.log("Status Response:", response.data); // Log the status response for inspection
            setStatusResponse(response.data); // Set the status response data
            setStatusError(null); // Reset any previous error
        } catch (error) {
            console.error('Error fetching status response:', error);
            setStatusError(error.response?.data?.message || "Error fetching status response");
            setStatusResponse(null); // Reset status response on error
        }
    };

    // Function to determine the icon based on status
    const getStatusIcon = (status) => {
        switch (status) {
            case 'read':
                return <FontAwesomeIcon icon={faCheckDouble} color="black" />;
            case 'failed':
                return <FontAwesomeIcon icon={faExclamationTriangle} color="red" />;
            case 'completed':
                return <FontAwesomeIcon icon={faCheck} color="green" />;
            case 'pending':
                return <FontAwesomeIcon icon={faCheck} color="orange" />;
            case 'delivered':
                return <FontAwesomeIcon icon={faCheckDouble} color="blue" />;
            default:
                return null;
        }
    };

    // Function to determine the color of the status text
    const getStatusTextColor = (status) => {
        return 'white';
    };

    if (loading) {
        return <div>Loading report data...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!reportData || !reportData.messages || reportData.messages.length === 0) {
        return <div>No data available for this template.</div>;
    }

    return (
        <>
            <div className="repfull">
                <div className='report-container'>
                    <h4 style={{ paddingTop: '0px', color: '#00417d' }}>IISMS-Manager <span> > </span> Campaign Report</h4>
                    <div className="containersd">
                        <div className="box box1" id='fscrds'>
                        <h2 style={{fontWeight:'700'}}>DASHBOARD</h2>
                            <h1 style={{ color:'#FF6384'}}>₹20000</h1>
                            <p className='mb-0'>Total spending on this campaign</p> 
                           
                            <h1>81</h1>
                            <p className='mb-0'>Total audience in this campaign</p>
                            <br />
                         
                            <br />
                            <button className="btn" id='thsunm'>
                               THE SUMMARY
                            </button>
                        </div>
                        <div className="box box2">
                            <LocationDistributionMap />
                        </div>
                        <div className="box box3">
                            <CampaignComparisonChart />
                        </div>
                    </div>

                    <div className="card-container">
                        {possibleStatuses.map((status, index) => (
                            <div
                                key={index}
                                className="box"
                                id='dashcards'
                                onClick={() => handleCardClick(status)} // Pass the status to the handler
                                style={{
                                    cursor: 'pointer',
                                    background: gradientColors[index % gradientColors.length], // Use gradient colors
                                }}
                            >
                                <div className="d-flex align-items-left">
                                    <div>
                                        <h2 className="text-uppercase text-white">
                                            {status ? status.toUpperCase() : 'NO STATUS AVAILABLE'}
                                        </h2>
                                        <h4 className="text-white">
                                            Audience: <span>{getStatusCount(status) || '0'}</span>
                                        </h4>
                                    </div>
                                    {/* <div className="ms-auto">
                                        {getStatusIcon(status)}
                                    </div> */}
                                </div>
                            </div>
                        ))}
                    </div>
<div className="tabling">
                    {statusResponse && (
                        <div className="status-response">
                            <h4 style={{ color: '#00417d' }}>Status Response</h4>
                            <div>
                                <h5 style={{ color: '#00417d' }}>Message Details</h5>
                                <div className="status-table-container">
                                    <table className="status-table">
                                        <thead>
                                            <tr>
                                                <th>Recipient ID</th>
                                                <th>Status Timestamp</th>
                                                {/* <th>Created At</th> */}
                                                <th>Updated At</th>
                                                <th>Errors</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {statusResponse.messages && statusResponse.messages.map((msg, index) => (
                                                <tr key={index}>
                                                    <td>{msg.recipient_id}</td>
                                                    <td>{new Date(msg.status_timestamp).toLocaleString()}</td>
                                                    {/* <td>{new Date(msg.created_at).toLocaleString()}</td> */}
                                                    <td>{new Date(msg.updated_at).toLocaleString()}</td>
                                                    <td>{msg.errors || 'None'}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="box box1" id='tabsicar'>
                            <CampaignComparisonChart />
                        
                    </div>
</div>
                    {statusError && <div className="error-message">Error: {statusError}</div>}
                </div>
            </div>
            <div className="cardddds">
                
            </div>
        </>
    );
};

export default TemplateReport;
