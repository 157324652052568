import React from 'react';
import './Chatbox.css'; // We'll add some styling for this too

const Chatbox = ({ headerText, headerVariables, bodyText, bodyVariables, footerText, buttonText }) => {
    // Replace {{n}} placeholders with corresponding variables in both header and body
    const replacePlaceholders = (text, variables) => {
        let replacedText = text;
        variables.forEach((variable, index) => {
            replacedText = replacedText.replace(`{{${index + 1}}}`, variable || `{{${index + 1}}}`);
        });
        return replacedText;
    };

    return (
        <>
            <div className="chadiv1">
                <div className="chatboxmod">
                    <div className="chat-headermod">{replacePlaceholders(headerText, headerVariables)}</div>
                    <div className="chat-bodymod">{replacePlaceholders(bodyText, bodyVariables)}</div>
                    <div className="chat-footermod">{footerText}</div>

                </div>
                <div className="chat-buttons12mod">
                    {buttonText && (
                        <button className="chat-button1mod">{buttonText}</button>
                    )}
                </div>
            </div>
        </>
    );
};

export default Chatbox;