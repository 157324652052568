import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ModifyTemplate.css';
import axios from 'axios';
import Chatbox from '../Chatbox/Chatbox';
import { uploadMedia, sendMessage } from '../ApiService/ApiService';


const ModifyTemplate = ({ code, name, campaign_id }) => {
    const [headerText, setHeaderText] = useState('');
    const [bodyText, setBodyText] = useState('');
    const [footerText, setFooterText] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [headerVariables, setHeaderVariables] = useState([]);
    const [bodyVariables, setBodyVariables] = useState([]);
    const [mobileNumber, setMobileNumber] = useState('');
    const [statuses, setStatuses] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isImageHeader, setIsImageHeader] = useState(false);
    const [isDocumentHeader, setIsDocumentHeader] = useState(false);
    const [isLocationHeader, setIsLocationHeader] = useState(false); // New State for Location Header
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [imageId, setImageId] = useState('');
    const [documentId, setDocumentId] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [locationName, setLocationName] = useState('');
    const [locationAddress, setLocationAddress] = useState('');
  
    const navigate = useNavigate();

    const [variableValue, setVariableValue] = useState('');

    useEffect(() => {
        if (code) {
            const components = JSON.parse(code)?.components || [];
            components.forEach((component) => {
                switch (component.type) {
                    case 'HEADER':
                        if (component.format === 'IMAGE') {
                            setIsImageHeader(true);
                            setIsDocumentHeader(false);
                            setIsLocationHeader(false);
                        } else if (component.format === 'DOCUMENT') {
                            setIsDocumentHeader(true);
                            setIsImageHeader(false);
                            setIsLocationHeader(false);
                        } else if (component.format === 'LOCATION') {
                            setIsLocationHeader(true); // Set to true if the header is location
                            setIsImageHeader(false);
                            setIsDocumentHeader(false);
                        } else {
                            setIsImageHeader(false);
                            setIsDocumentHeader(false);
                            setIsLocationHeader(false);
                            setHeaderText(component.text || '');
                            setHeaderVariables(component.example?.header_text || []);
                        }
                        break;
                    case 'BODY':
                        setBodyText(component.text || '');
                        setBodyVariables(component.example?.body_text?.flat() || []);
                        break;
                    case 'FOOTER':
                        setFooterText(component.text || '');
                        break;
                    case 'BUTTONS':
                        setButtonText(component.buttons.map((b) => b.text).join(', '));
                        break;
                    default:
                        break;
                }
            });
        }
    }, [code]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
        uploadFile(file, 'image');
    };

    const handleDocumentChange = (e) => {
        const file = e.target.files[0];
        setSelectedDocument(file);
        uploadFile(file, 'document');
    };

    const uploadFile = async (file, type) => {
        try {
            const response = await uploadMedia(file);
            const mediaId = response.data.data.id;
            if (type === 'image') setImageId(mediaId);
            else if (type === 'document') setDocumentId(mediaId);
        } catch (error) {
            console.error('Upload Error:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const mobileNumbers = mobileNumber.split(',').map(num => num.trim());

        for (let num of mobileNumbers) {
            if (!/^[6-9]\d{9}$/.test(num)) {
                alert(`Please enter a valid 10-digit Indian mobile number: ${num}`);
                return;
            }
        }

        setStatuses([]);

        for (let num of mobileNumbers) {
            let headerParameters = [];

            if (isImageHeader) {
                if (imageId) {
                    headerParameters = [{ type: 'image', image: { id: imageId } }];
                } else {
                    alert("Please upload an image for the header.");
                    return;
                }
            } else if (isDocumentHeader) {
                if (documentId) {
                    headerParameters = [{ type: 'document', document: { id: documentId } }];
                } else {
                    alert("Please upload a document for the header.");
                    return;
                }
            } else if (isLocationHeader) {
                if (latitude && longitude && locationName && locationAddress) {
                    headerParameters = [{
                        type: 'location',
                        location: {
                            latitude,
                            longitude,
                            name: locationName,
                            address: locationAddress,
                        },
                    }];
                } else {
                    alert("Please fill all location details.");
                    return;
                }
            } else {
                headerParameters = headerVariables.map((variable) => ({ type: 'text', text: variable }));
            }

            const bodyParameters = bodyVariables.map((variable) => ({ type: 'text', text: variable }));
            const payload = {
                to: num,
                templateName: name,
                templateData: {
                    name,
                    language: { code: 'en_US' },
                    components: [
                        { type: 'HEADER', parameters: headerParameters },
                        { type: 'BODY', parameters: bodyParameters },
                    ],
                },
                campaign_id,
            };

            try {
                const response = await sendMessage(payload);
                console.log('Type of campaign_id:', typeof campaign_id);
                console.log("payload:",payload)
                setStatuses((prev) => [...prev, { num, status: 'Success', response: response.data }]);
                setShowModal(true);
            } catch (error) {
                setStatuses((prev) => [...prev, { num, status: 'Failed', error: error.message }]);
            }
        }
    };

    const handleModalClose = () => {
        setShowModal(false); // Close the modal
        navigate('/main/Home'); // Navigate to home page
    };
    const handleHeaderVariableChange = (index, newValue) => {
        setHeaderVariables((prevVariables) => {
            const updatedVariables = [...prevVariables];
            updatedVariables[index] = newValue; // Update the variable at the specified index
            return updatedVariables;
        });
    };
    const handleBodyVariableChange = (index, newValue) => {
        setBodyVariables((prevVariables) => {
            const updatedVariables = [...prevVariables];
            updatedVariables[index] = newValue; // Update the variable at the specified index
            return updatedVariables;
        });
    };

    return (
        <div className="modify-template">
            <div className="mainheadingleft">
            <form onSubmit={handleSubmit} className="modify-template-container">
                {/* <h3>Modify WhatsApp Template</h3> */}

                {/* Header Section */}
                <div className="box">
                    <h5>Header</h5>
                    {isImageHeader ? (
                        <>
                            <p>Select an Image for the Header:</p>
                            <input type="file" accept="image/*" onChange={handleImageChange} />
                        </>
                    ) : isDocumentHeader ? (
                        <>
                            <p>Select a PDF Document for the Header:</p>
                            <input type="file" accept="application/pdf" onChange={handleDocumentChange} />
                        </>
                    ) : isLocationHeader ? ( // Check if it's a location header
                        <>
                            <p>Location Details</p>
                            <input
                                            className='sendinput'
                                type="text"
                                value={latitude}
                                onChange={(e) => setLatitude(e.target.value)}
                                placeholder="Enter Latitude"
                            />
                            <input
                                            className='sendinput'
                                type="text"
                                value={longitude}
                                onChange={(e) => setLongitude(e.target.value)}
                                placeholder="Enter Longitude"
                            />
                            <input
                                            className='sendinput'
                                type="text"
                                value={locationName}
                                onChange={(e) => setLocationName(e.target.value)}
                                placeholder="Enter Location Name"
                            />
                            <input
                                type="text"
                                            className='sendinput'
                                value={locationAddress}
                                onChange={(e) => setLocationAddress(e.target.value)}
                                placeholder="Enter Address"
                            />
                        </>
                    ) : (
                        <>
                            <p>Text Header</p>
                            {headerVariables.map((variable, index) => (
                                <input
                                    className="sendinput"
                                    key={index}
                                    type="text"
                                    value={variable}
                                    onChange={(e) => handleHeaderVariableChange(index, e.target.value)}
                                    placeholder={`Header Variable ${index + 1}`}
                                />

                            ))}
                        </>
                    )}
                </div>

                {/* Body Section */}
                <div className="box">
                    <h5>Body</h5>
                    <textarea
                            className='sendinput'
                        value={bodyText}
                        onChange={(e) => setBodyText(e.target.value)}
                        placeholder="Body Text"
                        disabled 
                        style={{height:'auto'}}
                    />
                    {bodyVariables.map((variable, index) => (
                        <input
                            className='sendinput'
                            key={index}
                            type="text"
                            value={variable}
                            onChange={(e) => handleBodyVariableChange(index, e.target.value)}
                            placeholder={`Body Variable ${index + 1}`}
                        />
                    ))}
                </div>

                {/* Footer Section */}
                {/* <div className="section">
                    <h4>Footer</h4>
                    <input
                        type="text"
                        value={footerText}
                        onChange={(e) => setFooterText(e.target.value)}
                        placeholder="Footer Text"
                    />
                </div> */}

                {/* Button Section */}
                {/* <div className="section">
                    <h4>Buttons</h4>
                    <input
                        type="text"
                        value={buttonText}
                        onChange={(e) => setButtonText(e.target.value)}
                        placeholder="Button Text"
                    />
                </div> */}

                {/* Mobile Number Section */}
                <div className="box">
                    <h5>Recipient Mobile Number</h5>
                    <input
                            className='sendinput'
                        type="text"
                        value={mobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value)}
                        placeholder="Enter comma-separated numbers"
                    />
                    <br />
                    <span style={{paddingTop:'10px'}}>Add multiple numbers separated by comas.</span>
                </div>
                    <div className="button-container">
                        <button type="submit" className='submit-button'>Send</button>
                </div>
            </form>
             </div>
            <div className="box chat-previewmod">
                <h4 className='hh44'>Live Chat Preview</h4>
                <Chatbox
                    headerText={headerText}
                    headerVariables={headerVariables}
                    bodyText={bodyText}
                    bodyVariables={bodyVariables}
                    footerText={footerText}
                    buttonText={buttonText}
                />
            </div>
            {/* {statuses.length > 0 && (
                <div className="status-container">
                    <h4>Status</h4>
                    <ul>
                        {statuses.map((status, index) => (
                            <li key={index}>
                                {status.num}: {status.status}
                                {status.response && <pre>{JSON.stringify(status.response, null, 2)}</pre>}
                                {status.error && <p>Error: {status.error}</p>}
                            </li>
                        ))}
                    </ul>
                </div>
            )} */}

            {showModal && (
                <div className="modal-overlaymo">
                    <div className="modal-contentmo">
                        <p>Template has been successfully sending in the background you can proceed with another activirt</p>
                        <button onClick={handleModalClose}>OK</button>
                    </div>
                </div>
            )}
        </div>

    );
};

export default ModifyTemplate;
