// SmoothLineChart.jsx
import React, { useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    CategoryScale
} from 'chart.js';

ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale);

const LocationDistributionMap = () => {
    const chartRef = useRef(null);

    // Create gradient fill with safe checks
    const getGradient = (ctx, chartArea) => {
        if (!ctx || !chartArea) return 'rgba(255, 99, 132, 0.2)'; // Default color if ctx or chartArea is missing

        const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(0, 'rgba(255, 99, 132, 0.2)'); // Light pink
        gradient.addColorStop(0.5, 'rgba(153, 102, 255, 0.2)'); // Light purple
        gradient.addColorStop(1, 'rgba(54, 162, 235, 0.2)'); // Light blue
        return gradient;
    };

    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        datasets: [
            {
                label: 'Dataset 1',
                data: [30, 45, 32, 52, 42, 60, 50],
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: (context) => {
                    const chart = chartRef.current && chartRef.current.chartInstance;
                    const chartCtx = chart?.ctx;
                    const chartArea = chart?.chartArea;
                    return getGradient(chartCtx, chartArea); // Safely get gradient
                },
                pointBackgroundColor: 'rgba(255, 99, 132, 1)',
                pointBorderColor: '#fff',
                pointHoverRadius: 6,
                pointRadius: 4,
                tension: 0.4, // Curve smoothness
                fill: true,
            },
            {
                label: 'Dataset 2',
                data: [40, 35, 40, 45, 37, 55, 48],
                borderColor: 'rgba(153, 102, 255, 1)',
                backgroundColor: (context) => {
                    const chart = chartRef.current && chartRef.current.chartInstance;
                    const chartCtx = chart?.ctx;
                    const chartArea = chart?.chartArea;
                    return getGradient(chartCtx, chartArea); // Safely get gradient
                },
                pointBackgroundColor: 'rgba(153, 102, 255, 1)',
                pointBorderColor: '#fff',
                pointHoverRadius: 6,
                pointRadius: 4,
                tension: 0.4, // Curve smoothness
                fill: true,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    color: '#666',
                },
            },
            tooltip: {
                enabled: true,
                backgroundColor: 'rgba(0,0,0,0.7)',
            },
        },
        scales: {
            x: {
                display: true,
                title: {
                    display: true,
                    text: 'Months',
                    color: '#666',
                },
                grid: {
                    display: false,
                },
            },
            y: {
                display: true,
                title: {
                    display: true,
                    text: 'Value',
                    color: '#666',
                },
                grid: {
                    color: 'rgba(200, 200, 200, 0.2)',
                },
            },
        },
    };

    return (
        <div style={{ width: '100%', maxWidth: '600px', margin: '0 auto', height: '300px' }}>
            <Line ref={chartRef} data={data} options={options} />
        </div>
    );
};

export default LocationDistributionMap;
