import React, { useEffect, useState } from 'react';
import './ChatPreview.css';

const ChatPreview = ({ headerText, bodyText, footerText, buttons, showAdditionalButton, additionalButtonText, imageUrl }) => {
    const [currentTime, setCurrentTime] = useState('');

    useEffect(() => {
        const updateTime = () => {
            const time = new Date();
            const formattedTime = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            setCurrentTime(formattedTime);
        };

        updateTime();
        const intervalId = setInterval(updateTime, 60000); // Update time every minute

        return () => clearInterval(intervalId); // Clean up interval on component unmount
    }, []);

    return (
        <div className="boximage">
            <div className="chat-preview">
                <div className="chadiv1">

                <div className="chat-bubble">
                    {headerText && (
                        <div className="chat-header">
                            <p className="bold-text">{headerText}</p>
                        </div>
                    )}
                    {imageUrl && (
                        <div className="chat-image">
                            <img src={imageUrl} alt="Header" className="header-image" />
                        </div>
                    )}
                    {bodyText && (
                        <div className="chat-body">
                            <p>{bodyText}</p>
                        </div>
                    )}
                    {footerText && (
                        <div className="chat-footer">
                            <p className="text">{footerText}</p>
                        </div>
                    )}
                    <div className="chat-time">{currentTime}</div>
                    
                </div>
                {showAdditionalButton && (
                    <div className="additional-button-container">
                        <button className="additional-button">
                            {additionalButtonText}
                        </button>
                    </div>
                )}
                {buttons.length > 0 && (
                    <div className="chat-buttons-pr">
                        {buttons.map((button, index) => (
                            <button key={index} className="chat-button12-pr">
                                {button.text} {/* Ensure each button displays its own text */}
                            </button>
                        ))}
                    </div>
                )}
            </div>
            </div>
        </div>
    );
};

export default ChatPreview;
