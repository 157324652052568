import React from 'react';
import './History.css';

const History = () => {
    // Dummy history data
    const historyData = [
        { id: 1, action: 'Sent WhatsApp Campaign', date: '2024-11-10', status: 'Completed' },
        { id: 2, action: 'Edited Template', date: '2024-11-09', status: 'Pending' },
        { id: 3, action: 'Viewed Campaign Report', date: '2024-11-08', status: 'Completed' },
        { id: 4, action: 'Added New Template', date: '2024-11-07', status: 'Completed' },
        { id: 5, action: 'Deleted Template', date: '2024-11-06', status: 'Completed' },
        { id: 6, action: 'Edited Template', date: '2024-11-09', status: 'Pending' }
    ];

    return (
        <div className="history-container">
            <h2>History</h2>
            <table className="history-table">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {historyData.map(item => (
                        <tr key={item.id}>
                            <td>{item.action}</td>
                            <td>{item.date}</td>
                            <td className={`status ${item.status.toLowerCase()}`}>{item.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default History;
