import React, { useState } from 'react';
import axios from 'axios';
import './CreateFlow.css'

const CreateFlow = ({ onClose }) => {
    const [flowName, setFlowName] = useState('');
    const [categories, setCategories] = useState([]);
    const [responseMessage, setResponseMessage] = useState('');

    const WAID = '312274045310951';
    const BASE_URL = 'https://graph.facebook.com/v17.0';
    const ACCESS_TOKEN = 'EAAN8R33mcjsBO8WQ4pNuLeZCcd5LrIWrzc7c3iph04Ai37ZC168JpfZCm5ihSDrjhiT4biA0UZBZA3iL71b0xoHJ5HF6ea3HOgCP6rhWP9ZCIlSYnNFXZCN1gWVY7ZA0RrMM19icJEvIueX2ZCS2L0pzCvR5tad7GmdZCBtv1bCzgNa1SSxt8LJd6d4ZAIOvNXnBZCbCFavqR06OD0ZB1zlhYJtJJcFhs98JXsn3BGri5cugZD'; // Replace with a valid token

    const handleCreateFlow = async () => {
        try {
            const payload = {
                name: flowName,
                categories: categories.length ? categories : ['OTHER'],
            };

            const response = await axios.post(
                `${BASE_URL}/${WAID}/flows`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${ACCESS_TOKEN}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            const flowId = response.data.id;
            localStorage.setItem('flowId', flowId);
           
console.log("paload:", payload);
            console.log("Flow Id:", flowId);
            setResponseMessage(`Flow created successfully! ID: ${flowId}`);

            // Close modal after 3 seconds
            setTimeout(onClose, 3000);
        } catch (error) {
            setResponseMessage('Failed to create the flow.');
            console.error('Error details:', error.response ? error.response.data : error);
        }
    };

    return (
        <div className="create-flow-container">
            <h2 style={{textAlign:'center'}}>Create a New Flow</h2>

            <div>
                <label>Flow Name:</label>
                <input
                    type="text"
                    value={flowName}
                    onChange={(e) => setFlowName(e.target.value)}
                    placeholder="Enter flow name"
                />
            </div>

            <div className="category-select">
                <label>Categories:</label>
                <select
                    multiple
                    value={categories}
                    onChange={(e) =>
                        setCategories([...e.target.selectedOptions].map((opt) => opt.value))
                    }
                >
                    <option value="SIGN_UP">SIGN_UP</option>
                    <option value="SIGN_IN">SIGN_IN</option>
                    <option value="APPOINTMENT_BOOKING">APPOINTMENT_BOOKING</option>
                    <option value="LEAD_GENERATION">LEAD_GENERATION</option>
                    <option value="CONTACT_US">CONTACT_US</option>
                    <option value="CUSTOMER_SUPPORT">CUSTOMER_SUPPORT</option>
                    <option value="SURVEY">SURVEY</option>
                    <option value="OTHER">OTHER</option>
                </select>
            </div>

            <button className="create-button" onClick={handleCreateFlow}>
                Create Flow
            </button>

            {responseMessage && <div className="response-message">{responseMessage}</div>}
        </div>
    );
};

export default CreateFlow;
