import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaBold, FaItalic, FaSmile, FaPlusCircle, FaInfoCircle } from 'react-icons/fa';
import './Cathead.css';

const Cathead = ({
    headerText,
    onHeaderTextChange,
    onVariableAdd,
    onVariableRemove,
    onVariableChange,
    variableAdded,
    variableValue,
}) => {
    const [showMessage, setShowMessage] = useState(false);

    // Handle adding the variable to the header text
    const addVariable = () => {
        if (!variableAdded) {
            onHeaderTextChange(headerText + ' {{1}}'); // Directly append the variable to the text
            onVariableAdd();
            setShowMessage(false);
        } else {
            setShowMessage(true);
        }
    };

    // Handle removing the variable from the header text
    const removeVariable = () => {
        onHeaderTextChange(headerText.replace(' {{1}}', '')); // Remove the variable from text
        onVariableRemove();
        setShowMessage(false);
    };

    return (
        <div className="header-container">
            <p className="text11">
                Header <span className="optional">• Optional</span>
            </p>

            {/* Header input field */}
            <div className="header-section">
                <input
                    type="text"
                    placeholder="Enter header text..."
                    className="header-input"
                    value={headerText}
                    maxLength={50}
                    onChange={(e) => onHeaderTextChange(e.target.value)} // Update parent state
                />

                {/* Toolbar buttons */}
                <div className="toolbar-button-group">
                    <button className="toolbar-button" aria-label="Bold text">
                        <FaBold />
                    </button>
                    <button className="toolbar-button" aria-label="Info">
                        <FaInfoCircle />
                    </button>
                    <button className="toolbar-button" aria-label="Italic text">
                        <FaItalic />
                    </button>
                    <button className="toolbar-button" aria-label="Emoji">
                        <FaSmile />
                    </button>
                    <button
                        className="toolbar-button"
                        onClick={addVariable}
                        disabled={variableAdded} // Disable if variable is already added
                        aria-label="Add variable"
                    >
                        <FaPlusCircle /> Add Variable
                    </button>
                </div>

                {/* Display variable input if a variable is added */}
                {variableAdded && (
                    <div className="variable-input-group">
                        <span className="variable-label">{`{{1}}`}</span>
                        <input
                            type="text"
                            className="variable-input"
                            placeholder="Enter value"
                            value={variableValue}
                            onChange={(e) => onVariableChange(e.target.value)} // Update variable value
                        />
                        <button
                            className="remove-variable-button"
                            onClick={removeVariable}
                            aria-label="Remove variable"
                        >
                            Remove
                        </button>
                    </div>
                )}

                {/* Warning message if user tries to add a variable more than once */}
                {showMessage && (
                    <p className="warning-message">You can add only one variable.</p>
                )}
            </div>
        </div>
    );
};

Cathead.propTypes = {
    headerText: PropTypes.string.isRequired,
    onHeaderTextChange: PropTypes.func.isRequired,
    onVariableAdd: PropTypes.func.isRequired,
    onVariableRemove: PropTypes.func.isRequired,
    onVariableChange: PropTypes.func.isRequired,
    variableAdded: PropTypes.bool.isRequired,
    variableValue: PropTypes.string.isRequired,
};

export default Cathead;
