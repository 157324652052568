// SpendingChart.jsx
import React from 'react';
import { Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,   // For Pie and Doughnut charts
    PointElement, // For Line charts
    LineElement,
    Title,
    Tooltip,
    Legend
);



const SpendingChart = () => {
    const data = {
        labels: ['Message Costs', 'Handling Fees', 'Other'],
        datasets: [
            {
                data: [300, 150, 50], // Dummy data
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            },
        ],
    };

    const options = {
        responsive: true,
    };

    return <Pie data={data} options={options} />;
};

export default SpendingChart;
