import React, { useState } from 'react';
import axios from 'axios';
import { FaFileUpload } from 'react-icons/fa';

const FileUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [accessToken, setAccessToken] = useState('');
  const [message, setMessage] = useState('');

  const handleFileChange = (event) => {
    if (event.target.files) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleAccessTokenChange = (event) => {
    setAccessToken(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedFile) {
      setMessage('Please select a file to upload.');
      return;
    }

    const formData = new FormData();

    // Set the correct field name based on the file type
    if (selectedFile.type.startsWith('image/')) {
      formData.append('imageFile', selectedFile); // For image upload
    } else if (selectedFile.type.startsWith('video/')) {
      formData.append('videoFile', selectedFile); // For video upload
    } else if (selectedFile.type === 'application/pdf') {
      formData.append('videoFile', selectedFile); // For PDF upload
    } else {
      setMessage('Invalid file type. Please select an image, video, or PDF.');
      return;
    }

    try {
      const response = await axios.post('https://api.lohith.in:3007/api/v1/wa/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      console.log('Upload Response:', response); // Log the full response object
      setMessage('Upload successful: ' + response.data.message);

      // Check if response contains media ID for images, videos, and PDFs
      if (response.data.data && response.data.data.media_id) {
        console.log('Media ID:', response.data.data.media_id); // Log media ID
      } else {
        console.log('No media ID received for this upload.');
      }

    } catch (error) {
      console.error('Upload Error:', error); // Log any errors
      setMessage('Upload failed: ' + error.message);
    }
  };

  return (
    <div className="container">
      <h2>File Upload <FaFileUpload /></h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="fileInput">Select an image, video, or PDF to upload:</label>
          <input
            type="file"
            id="fileInput"
            accept="image/*, video/*, application/pdf"  // Allow image, video, and PDF file types
            onChange={handleFileChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="accessToken">Access Token:</label>
          <input
            type="text"
            id="accessToken"
            value={accessToken}
            onChange={handleAccessTokenChange}
            className="form-control"
            placeholder="Enter your access token"
          />
        </div>
        <button type="submit" className="btn btn-primary">Upload</button>
      </form>
      {message && <div className="alert alert-info mt-3">{message}</div>}
    </div>
  );
};

export default FileUpload;
