import React from 'react';
import './FooterSection.css';

const FooterSection = ({ footerText, onFooterTextChange }) => {
    const handleTextChange = (e) => {
        const newText = e.target.value;

        // Regular expression to match emojis
        const emojiPattern = /([\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{1FB00}-\u{1FBFF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{FE00}-\u{FE0F}]|[\u{1F018}-\u{1F270}]|[\u{1F100}-\u{1F1FF}])/gu;

        // Remove any emojis from the input text
        if (!emojiPattern.test(newText)) {
            onFooterTextChange(e);  // Only update if no emojis are detected
        }
    };

    return (
        <div>
            <p className='txt1'>Footer<span className="optional"> • Optional</span></p>
            <div className="footer-section">
                <input
                    type="text"
                    placeholder="Enter footer text..."
                    className="footer-input"
                    value={footerText}
                    maxLength={100}
                    onChange={handleTextChange}
                />
                <div className="char-count">Characters: {footerText.length} / 100</div>
            </div>
        </div>
    );
};

export default FooterSection;
