import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchTemplates, createTemplate } from '../ApiService/ApiService';
import './TemplateList.css';
import { useTheme } from '../ThemeContext';

const TemplateList = () => {
    const { theme } = useTheme();
    const [templates, setTemplates] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('Processing...');
    const [isSuccess, setIsSuccess] = useState(false);
    const navigate = useNavigate();

    const fetchTemplateData = useCallback(async () => {
        setLoading(true);
        try {
            const templatesData = await fetchTemplates();
            console.log("templatedata:", templatesData);
            setTemplates(templatesData);
        } catch (err) {
            if (err.response && err.response.status === 403) {
                navigate('/login');
            } else {
                setError(err);
                console.error('Error fetching templates:', err);
            }
        } finally {
            setLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        fetchTemplateData();
    }, [fetchTemplateData]);

    const handleRowClick = (template) => {
        if (template.status && template.status.toLowerCase() === 'closed') {
            navigate('/main/template_report', { state: { templateId: template.id } });
        } else {
            navigate(`/main/send-template?template_id=${template.id}`);
        }
    };

    // Helper function to get the count for each status
    const getStatusCount = (statusCounts, status) => {
        const statusObj = statusCounts.find((sc) => sc.status === status);
        return statusObj ? statusObj.count : 0;
    };

    return (
        <>
            {error && <p className="error">ERROR: {error.message}</p>}

            <div className="box" style={{ overflowX: 'auto', margin: '10px' }}>
                <h5>Recent Campaigns</h5>
                <table className={`template-table`}>
                    <thead>
                        <tr>
                            <th rowSpan="2">Campaign ID</th>
                            <th rowSpan="2">Campaign Name</th>
                            <th rowSpan="2">WhatsApp Template</th>
                      
                          
                       
                       
                            <th>Read</th>
                            <th>Created</th>
                            <th>Delivered</th>
                            <th>Failed</th>
                            <th rowSpan="2">Status</th>
                        </tr>
                    </thead>

                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="8">Loading...</td>
                            </tr>
                        ) : (
                            templates.map((template, index) => (
                                <tr key={`${template.id}-${index}`} onClick={() => handleRowClick(template)}>
                                    <td>{template.id}</td>
                                    <td>{template.name}</td>
                                    <td>{template.whatsapp_template_name}</td>
                                   
                                    <td>{getStatusCount(template.statusCounts, 'read')}</td>
                                    <td>{getStatusCount(template.statusCounts, 'created')}</td>
                                    <td>{getStatusCount(template.statusCounts, 'delivered')}</td>
                                    <td>{getStatusCount(template.statusCounts, 'failed')}</td>
                                    <td>
                                        <span className={`badge ${template.status === 'closed' ? 'badge-closed' : 'badge-created'}`}>
                                            {template.status}
                                        </span>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default TemplateList;
