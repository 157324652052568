import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import ModifyTemplate from '../ModifyTemplate/ModifyTemplate'
import './CreateWhatsappTemplate.css';

const CreateWhatsappTemplate = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams(); // Extracting id from URL
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [selectedTemplateId, setSelectedTemplateId] = useState('');
    const [selectedCode, setSelectedCode] = useState(null);
    const [mappingData, setMappingData] = useState(null);
    const [selectedHeaderHandlerId, setSelectedHeaderHandlerId] = useState(null); // Add this

    const query = new URLSearchParams(location.search);
    const templateId = query.get('template_id'); // Extracting template_id from query parameters

    const replacePlaceholders = (text, example) => {
        if (!example) return text;
        let replacedText = text;
        example.forEach((ex, index) => {
            replacedText = replacedText.replace(`{{${index + 1}}}`, ex);
        });
        return replacedText;
    };

    const getUserIdFromToken = () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decoded = jwtDecode(token);
                return decoded.id;
            } catch (err) {
                console.error('Error decoding token:', err);
            }
        }
        return null;
    };



    
    const fetchTemplates = async () => {
        const userId = getUserIdFromToken();
        if (!userId) {
            setError('Invalid token or user ID not found');
            setLoading(false);
            return;
        }

        try {
            const response = await axios.get(`https://api.lohith.in:3007/api/v1/wa/get_wa_ap_templates/${userId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            const templatesWithPreview = response.data.templates.map((template) => {
                let header = '';
                let body = '';
                let footer = '';
                let button = '';

                try {
                    const code = JSON.parse(template.code || '{}');
                    code.components.forEach((component) => {
                        switch (component.type) {
                            case 'HEADER':
                                header = replacePlaceholders(component.text, component.example?.header_text || []);
                                break;
                            case 'BODY':
                                body = replacePlaceholders(component.text, component.example?.body_text?.flat() || []);
                                break;
                            case 'FOOTER':
                                footer = replacePlaceholders(component.text, component.example?.footer_text || []);
                                break;
                            case 'BUTTONS':
                                button = component.buttons.map((b) => b.text).join(', ');
                                break;
                            default:
                                break;
                        }
                    });
                } catch (e) {
                    console.error('Error parsing code:', e);
                }

                return {
                    ...template,
                    header,
                    body,
                    footer,
                    button,
                };
            });

            setTemplates(templatesWithPreview);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching templates:', err);
            setError('Failed to fetch templates');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTemplates();
    }, []);

    const getStatusClass = (status) => {
        switch (status) {
            case 'APPROVED':
                return 'status-approved';
            case 'REJECTED':
                return 'status-rejected';
            case 'PENDING':
                return 'status-pending';
            default:
                return '';
        }
    };
    const handleDropdownChange = async (event) => {
        const selectedId = event.target.value;

        // Check if a new template is selected; if so, reset previous selection data
        if (selectedId !== selectedTemplateId) {
            setSelectedTemplate(null);
            setSelectedCode(null);
            setSelectedHeaderHandlerId(null); // Reset header handler ID
        }

        setSelectedTemplateId(selectedId);

        const template = templates.find((tpl) => String(tpl.id) === String(selectedId));
        setSelectedTemplate(template || null);
        setSelectedCode(template ? template.code : null); // Set the selected code directly

        if (template) {
            console.log('Selected Template:', template);

            let headerHandlerId = null;

            try {
                const code = JSON.parse(template.code || '{}');
                const headerComponent = code.components.find(
                    (comp) => comp.type === 'HEADER' && comp.format === 'IMAGE'
                );

                if (headerComponent && headerComponent.example && headerComponent.example.header_handle) {
                    headerHandlerId = headerComponent.example.header_handle[0];
                    console.log('Header Handler ID:', headerHandlerId);
                } else {
                    console.log('No header handler ID found for image header.');
                }
            } catch (e) {
                console.error('Error parsing template code:', e);
            }

            setSelectedHeaderHandlerId(headerHandlerId);
        }

        if (selectedId) {
            const userId = getUserIdFromToken();
            const waTemplateId = template ? template.id : null;

            if (waTemplateId && userId && templateId) {
                console.log('Sending data to API:');
                console.log('template_id:', templateId);
                console.log('wa_template_id:', waTemplateId);
                console.log('userid:', userId);

                try {
                    const response = await axios.post(
                        `https://api.lohith.in:3007/api/v1/wamap/template-wa-mapping`,
                        {
                            template_id: templateId,
                            wa_template_id: waTemplateId,
                            userid: userId,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('token')}`,
                            },
                        }
                    );
                    setMappingData(response.data);
                    console.log('Mapping Data:', response.data);
                } catch (error) {
                    console.error('Error fetching mapping data:', error.response ? error.response.data : error.message);
                    setError('Failed to fetch mapping data');
                }
            } else {
                console.error('Missing wa_template_id or userId');
                setError('Missing wa_template_id or userId');
            }
        } else {
            setMappingData(null);
        }
    };


   

    const handleCreateNewTemplate = () => {
        navigate('/main/create-template');
    };

    return (
        <>
            <div className="cwt">
                <div className="box part1">
                    <h5>Create WhatsApp Template Here</h5>
                    <p className='dar'>Unlock the potential of personalized communication with our custom WhatsApp templates. Design templates that resonate with your audience and enhance engagement in a meaningful way. If you haven't created one yet, now's the perfect time! Click the button below to start designing your unique template and elevate your messaging experience today!</p>
                    <div className="btcre">
                    <button className="button-create" onClick={handleCreateNewTemplate}>
                        Create WhatsApp Template
                    </button>
                    </div>
                </div>
                <hr />
                <h4 style={{ padding: '10px', color: '#00417d' }}>IISMS-Manager <span>></span> Send Templates</h4>
                <div className="dropdown-preview-container">
                <h5>Select Your Template</h5>
                    <div className="dropdown-container-mod">
                        <select
                            className="dropdown-mod"
                            value={selectedTemplateId}
                            onChange={handleDropdownChange}
                        >
                            <option value="">Select Whatsapp Template</option>
                            {templates.map((template) => (
                                <option key={template.id} value={template.id}>
                                    {template.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <ModifyTemplate
                    code={selectedCode || ''} // Use empty string initially
                    name={selectedTemplate?.name || ''} // Show empty if no template is selected
                    status={selectedTemplate?.wa_template_status || 'PENDING'} // Default to 'PENDING'
                    category={selectedTemplate?.wa_template_category || 'General'} // Default category
                    campaign_id={templateId} // Pass the templateId as campaign_id
                    headerHandlerId={selectedHeaderHandlerId}  // Pass the extracted header handler ID
                />
            </div>
        </>
    );
};

export default CreateWhatsappTemplate;