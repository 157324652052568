import React, { useState } from 'react';
import './SelectTemplate.css';
import { useNavigate } from 'react-router-dom';
import { FaBullhorn, FaBell, FaLock } from 'react-icons/fa';
import marketingImage from '../../assets/images/marketing.png';
import utilityImage from '../../assets/images/utility.png';
import authenticationImage from '../../assets/images/auth.png';
import CreateTemplate from '../NextComponent/CreateTemplate';

const SelectTemplate = () => {
    // Initialize states
    const [activeDropdown, setActiveDropdown] = useState('Marketing'); // Set default to Marketing
    const [selectedOption, setSelectedOption] = useState({ Marketing: 'Custom' }); // Default option
    const [selectedCategory, setSelectedCategory] = useState('Marketing'); // Set default to Marketing
    const [showNextComponent, setShowNextComponent] = useState(false);
    const navigate = useNavigate();

    const dropdownOptions = {
        Marketing: [
            { heading: 'Custom', description: 'Send promotions or announcements to increase awareness and engagement.' },
        ],
        Utility: [
            { heading: 'Custom', description: 'Send messages about an existing order or account.' },
        ],
        Authentication: [],
    };

    const handleButtonClick = (category) => {
        setActiveDropdown(category);
        setSelectedCategory(category);
    };

    const handleOptionChange = (category, option) => {
        setSelectedOption({ ...selectedOption, [category]: option });
    };

    const handleNextClick = () => {
        if (selectedCategory === 'Marketing') {
            if (selectedOption['Marketing'] === 'Custom') {
                setShowNextComponent(true);
            } else if (selectedOption['Marketing'] === 'Catalogue') {
                navigate('/main/catalogue');
            } else if (selectedOption['Marketing'] === 'Flows') {
                navigate('/main/create_flow_template');
            }
        } else if (selectedCategory === 'Utility' && selectedOption['Utility'] === 'Custom') {
            setShowNextComponent(true);
        } else if (selectedCategory === 'Authentication') {
            if (selectedOption['Authentication'] === 'One-Time-Passcode') {
                navigate('/main/authotp');
            } else {
                alert('Please select a valid option.');
            }
        } else {
            alert('Please select a category and option.');
        }
    };

    const getCategoryImage = () => {
        switch (selectedCategory) {
            case 'Marketing':
                return marketingImage;
            case 'Utility':
                return utilityImage;
            case 'Authentication':
                return authenticationImage;
            default:
                return null;
        }
    };

    const handleBackClick = () => {
        setShowNextComponent(false);
    };

    if (showNextComponent) {
        return (
            <CreateTemplate
                onBackClick={handleBackClick}
                selectedCategory={selectedCategory}
            />
        );
    }

    return (
        <div className="select-template-container">
            <div className="bg">
                <div className="flhead">
                    <h4>IISMS-Messenger > Create Template</h4>
                </div>
                <div className="flexbox-container">
                    <div className="flexbox-item">
                        <h5>Set up your templates</h5>
                        <p>
                            Choose the category that best describes your message template. Then select the type of message you want to
                            send. <a href="#">Learn more</a>
                        </p>
                        <span className="buttons">
                            <button
                                className={activeDropdown === 'Marketing' ? 'active' : ''}
                                onClick={() => handleButtonClick('Marketing')}
                            >
                                <FaBullhorn className="icon" /> Marketing
                            </button>
                            <button
                                className={activeDropdown === 'Utility' ? 'active' : ''}
                                onClick={() => handleButtonClick('Utility')}
                            >
                                <FaBell className="icon" /> Utility
                            </button>
                            <button
                                className={activeDropdown === 'Authentication' ? 'active' : ''}
                                onClick={() => handleButtonClick('Authentication')}
                            >
                                <FaLock className="icon" /> Authentication
                            </button>
                        </span>

                        {activeDropdown && (
                            <div className="dropdown-box-seltemp" style={{ textAlign: 'left' }}>
                                <form className='inptradio'>
                                    {dropdownOptions[activeDropdown].map((option, index) => (
                                        <label
                                            key={index}
                                            className={`radio-option1 ${selectedOption[activeDropdown] === option.heading ? 'selected' : ''}`}
                                        >
                                            <input
                                                type="radio"
                                                name={activeDropdown}
                                                value={option.heading}
                                                checked={selectedOption[activeDropdown] === option.heading}
                                                onChange={() => handleOptionChange(activeDropdown, option.heading)}
                                            />
                                            <div className="option-content">
                                                <b>{option.heading}</b>
                                                <p>{option.description}</p>
                                            </div>
                                        </label>
                                    ))}
                                </form>
                            </div>
                        )}
                    </div>

                    <div className="flexbox-item1">
                        <h5>Template Preview</h5>
                        {selectedCategory && (
                            <img
                                src={getCategoryImage()}
                                alt={`${selectedCategory} Illustration`}
                                className="category-image"
                            />
                        )}
                    </div>
                </div>
                <div className="box " id='buttons-con'>
                <div className="buttons-container">
                    <button className="start-button">Discard</button>
                    <button className="end-button" onClick={handleNextClick}>
                        Next
                    </button>
                </div>
                </div>
            </div>
        </div>
    );
};

export default SelectTemplate;
