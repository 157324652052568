import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement } from 'chart.js';
import './DashboardCharts.css'
// Registering the necessary chart components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
);

const DashboardCharts = () => {
    // Bar chart data
    const barChartData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'Monthly Revenue',
                data: [3000, 4000, 5000, 4000, 6000, 7000, 8000],
                backgroundColor: '#42A5F5', // Blue
                borderRadius: 5,
                borderWidth: 1,
            },
        ],
    };

    // Line chart data
    const lineChartData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'Users Growth',
                data: [50, 75, 100, 120, 150, 200, 250],
                fill: true,
                backgroundColor: 'rgba(255, 99, 132, 0.2)', // Light pink
                borderColor: '#FF6384', // Red
                borderWidth: 2,
                tension: 0.4,
            },
        ],
    };

    return (
        <div className="dashboard-charts-container" style={{ display: 'flex', gap: '20px', justifyContent: 'left'}}>
            <div className="chart box " style={{ width: '45%' }}>
                <h3 style={{ textAlign: 'left' }}>Monthly Revenue</h3>
                <Bar data={barChartData} options={{ responsive: true }} />
            </div>
            <div className="chart box fdb" style={{ width: '45%' }}>
                <h3 style={{ textAlign: 'left' }}>Users Growth</h3>
                <Line data={lineChartData} options={{ responsive: true}} />
            </div>
        </div>
    );
};

export default DashboardCharts;
