// SentMessagesGraph.jsx
import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

// Register the required components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);



const SentMessagesGraph = () => {
    const data = {
        labels: ['Sent', 'Delivered', 'Opened', 'Responded'],
        datasets: [
            {
                label: 'Messages',
                data: [100, 90, 70, 50], // Dummy data
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: { position: 'top' },
        },
    };

    return <Bar data={data} options={options} />;
};

export default SentMessagesGraph;
