import React, { useState, useEffect } from 'react';
import CreateFlow from './CreateFlow'; // Import the CreateFlow component
import './CreateFlowModal.css'; // Import styles for animations and responsiveness

const CreateFlowModal = ({ isOpen, onClose }) => {
    const [showModal, setShowModal] = useState(false);

    // Add animation effect when opening the modal
    useEffect(() => {
        if (isOpen) {
            setShowModal(true);
        } else {
            setTimeout(() => setShowModal(false), 300); // Close after animation completes
        }
    }, [isOpen]);

    if (!showModal) return null; // Render nothing if modal is not open

    return (
        <div className={`modal-overlay ${isOpen ? 'show' : 'hide'}`}>
            <div className="modal-flow-content">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <CreateFlow onClose={onClose} /> {/* Pass onClose to CreateFlow */}
            </div>
        </div>
    );
};

export default CreateFlowModal;
