import React from 'react';
import underConstruction from '../assets/cons.gif'; // Adjust path as necessary
import './Manager.css'; // Import the CSS file
import Sidebar from './Sidebar/Sidebar';
import Navbar from './Navbar/Navbar';

const Manager = () => {
    return (
        <>
       
        <div className="container">
          
            <img src={underConstruction} alt="Under Construction" className="image" />
            <h1 className="heading">WEBSITE UNDER CONSTRUCTION</h1>
            <p className="message">We're working hard to bring you a great experience. Please check back soon!</p>
        </div>
        </>
    );
};

export default Manager;
