import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import {
    faHome,
    faUsers,
    faClipboardList,
    faComments,
    faCalendarAlt,
    faChartBar,
    faSignal,
    faBan,
    faAnchor,
    faKey,
    faCog,
    faChevronRight,
    faChevronDown,
    faDashboard,
} from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '../ThemeContext';
import { Link } from 'react-router-dom';
import './Sidebar.css';

import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const Sidebar = React.memo(() => {
    const navigate = useNavigate();
    const { theme } = useTheme();
    const [menuOpen, setMenuOpen] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState('/main/content1');
    const [activeSubmenu, setActiveSubmenu] = useState('');
    const [openMenu, setOpenMenu] = useState(null);
    const menuRef = useRef(null);
    const sidebarRef = useRef(null);

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('Processing...');
    const [isSuccess, setIsSuccess] = useState(false);

    const toggleSidebar = useCallback(() => setSidebarOpen(prevState => !prevState), []);

    const handleCreateTemplate = async () => {
        const randomNum = Math.floor(Math.random() * 10000);
        const templateName = `Template ${randomNum}`;
        const templateDescription = `This is a description for template ${randomNum}.`;

        setShowModal(true);
        setModalMessage('Processing...');
        setIsSuccess(false);

        try {
            const token = localStorage.getItem('token');
            const decodedToken = jwtDecode(token);
            const createdBy = decodedToken.id;

            const response = await axios.post(
                'https://api.lohith.in:3007/api/v1/templates/create-template',
                {
                    name: templateName,
                    description: templateDescription,
                    created_by: createdBy
                },
                { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } }
            );

            setTimeout(() => {
                setModalMessage('Template created successfully!');
                setIsSuccess(true);

                setTimeout(() => {
                    setShowModal(false);
                    const templateId = response.data.template.id;
                    navigate(`/main/send-template?template_id=${templateId}`);
                }, 1500);
            }, 2000);

        } catch (error) {
            console.error('Error creating new template:', error);
            setModalMessage('Failed to create a new template. Please try again later.');
            setIsSuccess(false);

            setTimeout(() => {
                setShowModal(false);
            }, 2000);
        }
    };

    const Modal = ({ message, showModal, onClose, isSuccess }) => {
        if (!showModal) return null;

        return (
            <div className="modal-backdroptl">
                <div className="modal-contenttl">
                    <div className="modal-bodytl">
                        {!isSuccess ? (
                            <div className="loading-spinnertl"></div>
                        ) : (
                            <div className="checkmark-containertl">
                                <div className="checkmarktl"></div>
                            </div>
                        )}
                        <h2>{message}</h2>
                    </div>
                    {isSuccess && (
                        <button onClick={onClose} className="close-btntl">
                            Close
                        </button>
                    )}
                </div>
            </div>
        );
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                sidebarRef.current && !sidebarRef.current.contains(event.target) &&
                !menuRef.current?.contains(event.target)
            ) {
                setMenuOpen(false);
                setSidebarOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleMenuItemClick = useCallback((path) => {
        setActiveMenu(path);
        setMenuOpen(false);
        if (window.innerWidth <= 768) {
            setSidebarOpen(false);
        }
    }, []);

    const handleSubmenuItemClick = useCallback((path) => {
        setActiveSubmenu(path);
        setMenuOpen(false);
        if (window.innerWidth <= 768) {
            setSidebarOpen(false);
        }
    }, []);

    const toggleSubmenu = useCallback((menu) => {
        setOpenMenu((prevMenu) => (prevMenu === menu ? null : menu));
    }, []);

    const renderSubmenu = useCallback((menu, icon, items) => (
        <li className={`sidebar-nav-item ${openMenu === menu ? 'open' : ''}`}>
            <span className="sidebar-nav-link" onClick={() => toggleSubmenu(menu)}>
                <div className="sidebar-menu-item">
                    <FontAwesomeIcon icon={icon} /> {menu}
                    <FontAwesomeIcon icon={openMenu === menu ? faChevronDown : faChevronRight} className="chevron-icon" />
                </div>
            </span>
            {openMenu === menu && (
                <ul className="sidebar-submenu">
                    {items.map((item, index) => (
                        <li key={index} className={`sidebar-submenu-item ${activeSubmenu === item.path ? 'active' : ''}`}>
                            {item.onClick ? (
                                <button className="sidebar-submenu-link" onClick={item.onClick}>
                                    {item.label}
                                </button>
                            ) : (
                                <Link to={item.path} className="sidebar-submenu-link" onClick={() => handleSubmenuItemClick(item.path)}>
                                    {item.label}
                                </Link>
                            )}
                        </li>
                    ))}
                </ul>
            )}
        </li>
    ), [openMenu, activeSubmenu, handleSubmenuItemClick, toggleSubmenu]);

    return (
        <>
            <button className={`hamburger-button ${sidebarOpen ? 'open' : ''}`} onClick={toggleSidebar}>
                ☰
            </button>
            <div
                className={`sidebar ${theme} ${sidebarOpen ? 'open' : ''}`}
                style={{ zIndex: '999' }}
                ref={sidebarRef}
            >
                <ul className="sidebar-nav">
                    <h3 className='sideh3'>DASHBOARD</h3>
{/*                 
                    <li className={`sidebar-nav-item ${activeMenu === '/main/Home' ? 'active' : ''}`}>
                        <Link to="/main/Home" className="sidebar-nav-link" onClick={() => handleMenuItemClick('/main/Home')}>
                            <FontAwesomeIcon icon={faHome} /> Home
                        </Link>
                    </li> */}
                    <li className={`sidebar-nav-item ${activeMenu === '/main/Dashboard' ? 'active' : ''}`}>
                        <Link to="/main/Dashboard" className="sidebar-nav-link" onClick={() => handleMenuItemClick('/main/Dashboard')}>
                            <FontAwesomeIcon icon={faComments} /> Dashboard
                        </Link>
                    </li>
                    <li className={`sidebar-nav-item ${activeMenu === '/main/create-template' ? 'active' : ''}`}>
                        <Link to="/main/create-template" className="sidebar-nav-link" onClick={() => handleMenuItemClick('/main/create-template')}>
                            <FontAwesomeIcon icon={faDashboard} /> Create Whatsapp Template
                        </Link>
                    </li>

                    {renderSubmenu('Templates', faClipboardList, [
                        { label: 'Display', path: '/main/templates' },
                        { label: 'Create', path: '/main/create-template' },
                        { label: 'Edit', path: '/main/under_construction' },
                        { label: 'Delete', path: '/main/site_construction' },
                    ])}

                    {renderSubmenu('Campaign', faUsers, [
                        {
                            label: 'Create',
                            onClick: handleCreateTemplate
                        },
                    ])}

                    {renderSubmenu('Report', faChartBar, [
                        { label: 'Summary', path: '/main/summary' },
                        { label: 'Detailed Download', path: '/main/under_construction' },
                        { label: 'Number Search', path: '/main/site_under_construction' },
                    ])}

                    <li className={`sidebar-nav-item ${activeMenu === '/main/content4' ? 'active' : ''}`}>
                        <Link to="/main/under_construction" className="sidebar-nav-link" onClick={() => handleMenuItemClick('/main/under_construction')}>
                            <FontAwesomeIcon icon={faComments} /> Live Chat
                        </Link>
                    </li>

                    {renderSubmenu('Schedule', faCalendarAlt, [
                        { label: 'Display', path: '/main/site_comming_soon' },
                        { label: 'Create', path: '/main/site_construction' },
                        { label: 'Delete', path: '/main/under_construction' },
                    ])}

                  

                    {renderSubmenu('MIS', faSignal, [
                        { label: 'WaBa Signal', path: '/main/site_under_construction' },
                        { label: 'Logs', path: '/main/under_construction' },
                        { label: 'Recharge', path: '/main/site_construction' },
                        { label: 'DayCount', path: '/main/site_comming_soon' },
                    ])}

                    {renderSubmenu('Black List', faBan, [
                        { label: 'Display', path: '/main/site_comming_soon' },
                        { label: 'Add', path: '/main/site_construction' },
                        { label: 'Remove', path: '/main/under_construction' },
                    ])}

                    {renderSubmenu('Boat', faAnchor, [
                        { label: 'Create', path: '/main/site_under_construction' },
                        { label: 'Display', path: '/main/under_construction' },
                        { label: 'Edit', path: '/main/site_construction' },
                        { label: 'Remove', path: '/main/site_comming_soon' },
                    ])}

                    {renderSubmenu('API', faKey, [
                        { label: 'View', path: '/main/site_under_construction' },
                        { label: 'Request', path: '/main/under_construction' },
                        { label: 'Remove', path: '/main/site_comming_soon' },
                    ])}

                    <li className={`sidebar-nav-item ${activeMenu === '/main/content8' ? 'active' : ''}`}>
                        <Link to="/main/under_construction" className="sidebar-nav-link" onClick={() => handleMenuItemClick('/main/under_construction')}>
                            <FontAwesomeIcon icon={faCog} /> Settings
                        </Link>
                    </li>
                </ul>
            </div>

            {/* Modal for loading and success */}
            <Modal
                showModal={showModal}
                message={modalMessage}
                isSuccess={isSuccess}
                onClose={() => setShowModal(false)} // Add this to allow closing the modal
            />
        </>
    );
});

export default Sidebar;
