import React, { useState, useEffect, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import './AdvBar.css';
import { useTheme } from '../ThemeContext';
const Modal = ({ message, showModal, onClose, isSuccess }) => {
    if (!showModal) return null;

    return (
        <div className="modal-backdroptl">
            <div className="modal-contenttl">
                <div className="modal-bodytl">
                    {!isSuccess ? (
                        <div className="loading-spinnertl"></div>
                    ) : (
                        <div className="checkmark-containertl">
                            <div className="checkmarktl"></div>
                        </div>
                    )}
                    <h2>{message}</h2>
                </div>
                {isSuccess && (
                    <button onClick={onClose} className="close-btntl">
                        Close
                    </button>
                )}
            </div>
        </div>
    );
};

const AdvBar = () => {
    const { theme } = useTheme(); // Use the theme from context
    const [templates, setTemplates] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false); // State for modal visibility
    const [modalMessage, setModalMessage] = useState('Processing...'); // State for modal message
    const [isSuccess, setIsSuccess] = useState(false); // State for success indicator
    const navigate = useNavigate();
    
        const fetchTemplates = useCallback(async () => {
            const token = localStorage.getItem('token');
            if (token) {
                const decodedToken = jwtDecode(token);
                const userId = decodedToken.id;
                try {
                    const response = await axios.get(`https://api.lohith.in:3007/api/v1/templates/get-templates/${userId}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });

                    if (response.data && Array.isArray(response.data.templates)) {
                        setTemplates(response.data.templates);
                    } else {
                        setTemplates([]);
                    }
                } catch (error) {
                    if (error.response && error.response.status === 403) {
                        navigate('/login');
                    } else {
                        setError(error);
                        console.error('Error fetching templates:', error);
                    }
                } finally {
                    setLoading(false);
                }
            }
        }, [navigate]);

        useEffect(() => {
            fetchTemplates();
        }, [fetchTemplates]);

const handleCreateTemplate = async () => {
        const randomNum = Math.floor(Math.random() * 10000);
        const templateName = `Template ${randomNum}`;
        const templateDescription = `This is a description for template ${randomNum}.`;
        setShowModal(true); // Show the modal with "Processing..."
        setModalMessage('Processing...');
        setIsSuccess(false); // Start with loading animation

        try {
            const token = localStorage.getItem('token');
            const decodedToken = jwtDecode(token);
            const createdBy = decodedToken.id;

            const response = await axios.post(
                'https://api.lohith.in:3007/api/v1/templates/create-template',
                {
                    name: templateName,
                    description: templateDescription,
                    created_by: createdBy
                },
                { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } }
            );

            const templateId = response.data.template.id;

            // Simulate a 2-second delay before showing success message
            setTimeout(() => {
                setModalMessage('Campaign created successfully!');
                setIsSuccess(true); // Show checkmark on success
                setTimeout(() => {
                    setShowModal(false); // Close modal after success message
                    navigate(`/main/send-template?template_id=${templateId}`);
                }, 1500); // Close after 1.5 seconds
            }, 2000); // Simulate 2 seconds for processing

            fetchTemplates(); // Refresh the list of templates after creation
        } catch (error) {
            console.error('Error creating new template:', error);
            setModalMessage('Failed to create a new template. Please try again later.');
            setTimeout(() => {
                setShowModal(false); // Close modal on failure
            }, 2000);
        }
    };
    
    return (
        <>
         
        {/* <div className={`bar ${theme}`}>
       
            <h1 className="heading">WELCOME TO IISSMS - Create Your Templates</h1>
            <div className="search-container">
                <i className="fas fa-search search-icon"></i>
                <input
                    type="search"
                    id="search"
                    placeholder="Search your Template"
                    className={`search-input ${theme}`}
                />
            </div>
                <button onClick={handleCreateTemplate} className="create-template-button">
                    Create Template
                </button>
            </div>  */}
            <div className="box" id='advertise' style={{margin:''}} >
                <h1 className="headingadv">WELCOME TO IISSMS </h1>
                <button onClick={handleCreateTemplate} className="create-template-button">
                    Create Campaign
                </button>
            </div>
            {/* <div className="box" id='headert' style={{ margin: '10px',padding:'0px' }}>
                <div className="search-container">
                    <i className="fas fa-search search-icon"></i>
                    <input
                        type="search"
                        id="search"
                        placeholder="Search your Template"
                        className={`search-input ${theme}`}
                    />
                </div>
                <button onClick={handleCreateTemplate} className="create-template-button">
                    Create Campaign
                </button>
            </div> */}
            <Modal message={modalMessage} showModal={showModal} onClose={() => setShowModal(false)} isSuccess={isSuccess} />
        </>
    );
};

export default AdvBar;
