import React from 'react';
import { Line, Bar, Doughnut, Radar, PolarArea } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    RadialLinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import './Dashboard.css';
import StatCard from './StatCard';
import DashCards from '../Dashboard/DashCards';
import History from './History';
import DashboardCharts from './DashboardCharts';
import AdvBar from '../AdvBar/AdvBar';
ChartJS.register(
    CategoryScale,
    LinearScale,
    RadialLinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

const Dashboard = () => {
    const cardColors = [
        
        'linear-gradient(135deg, #25d366 0%, #25d366 100%)',
        'linear-gradient(135deg, #25d366 0%, #25d366 100%)',
        'linear-gradient(135deg, #25d366 0%, #25d366 100%)',
        'linear-gradient(135deg, #25d366 0%, #25d366 100%)',
    ];

    const cardData = [
        { title: 'Bookings', value: '281', trend: '+55% than last week', chartType: 'line' },
        { title: "Today's Users", value: '2,300', trend: '+3% than last month', chartType: 'polarArea' },
        { title: 'Revenue', value: '34k', trend: '+1% than yesterday', chartType: 'doughnut' },
        { title: 'Followers', value: '+91', trend: 'Just updated', chartType: 'radar' },
    ];

    cardData.forEach((card, index) => {
        card.color = cardColors[index % cardColors.length];
    });

    const chartOptions = {
        maintainAspectRatio: false,
        plugins: {
            legend: { display: false },
        },
        scales: {
            x: { display: false },
            y: { display: false },
        },
    };

    const chartData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [
            {
                data: [65, 59, 80, 81, 56, 55],
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                borderColor: '#fff',
                borderWidth: 1.5,
                fill: true,
            }
        ]
    };

    const renderChart = (chartType) => {
        switch (chartType) {
            case 'line':
                return <Line data={chartData} options={chartOptions} />;
            case 'bar':
                return <Bar data={chartData} options={chartOptions} />;
            case 'doughnut':
                return (
                    <Doughnut
                        data={{
                            ...chartData,
                            datasets: [
                                {
                                    ...chartData.datasets[0],
                                    backgroundColor: ['#fd7e14', '#ff9f43', '#ffa726', '#fb8c00', '#ff7043'],
                                },
                            ],
                        }}
                        options={{ ...chartOptions, cutout: '70%' }}
                    />
                );
            case 'radar':
                return <Radar data={chartData} options={chartOptions} />;
            case 'polarArea':
                return <PolarArea data={chartData} options={chartOptions} />;
            default:
                return null;
        }
    };

    return (
        <>
       <AdvBar />
            <div className="allcharts" style={{ marginTop: '10px', }}>
                <div className="box" style={{ padding: '10px', marginBottom:"0px"}}>
                    <h4 style={{  color: '#00417d' }}>IISMS-Manager <span>></span> DASHBOARD</h4>
                </div>
                <div className="dashboard-cardsdas">
                    {cardData.map((card, index) => (
                        <div
                            key={card.title + index}
                            className="carddas"
                            style={{
                                background: card.color,
                                color: '#fff',
                                fontFamily: 'Arial, sans-serif',
                            }}
                        >
                            <div className="card-contentdas">
                                <div className="card-text">
                                    <p className="card-titledas">{card.title}</p>
                                    <p className="card-valuedas">{card.value}</p>
                                    <p className="card-trenddas">{card.trend}</p>
                                </div>
                                <div className="card-graphdas">
                                    {renderChart(card.chartType)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <StatCard />
                <div className=" cardtotal">
                    <div className="temda"> <DashCards /></div>
                    <div className="box hisbo"><History /></div>

                </div>
                <DashboardCharts />
            </div>
          
        </>
    );
};

export default Dashboard;
