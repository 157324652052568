import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import ChatPreview from "./TemplateCard"; // Ensure the path is correct
import "./TemplatePreviews.css";

const TemplatePreviews = () => {
    const [templates, setTemplates] = useState([]);
    const [filteredTemplates, setFilteredTemplates] = useState([]); // State to store filtered templates
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState(''); // Search bar state
    const [selectedCategory, setSelectedCategory] = useState(''); // Category filter state
    const [categories, setCategories] = useState([]); // Categories for filtering

    const getUserIdFromToken = () => {
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const decoded = jwtDecode(token);
                return decoded.id;
            } catch (err) {
                console.error("Error decoding token:", err);
            }
        }
        return null;
    };

    useEffect(() => {
        const fetchTemplates = async () => {
            const userId = getUserIdFromToken();
            const token = localStorage.getItem("token");

            if (!userId || !token) {
                setError("Missing userId or token");
                setLoading(false);
                return;
            }

            try {
                const response = await fetch(
                    `https://api.lohith.in:3007/api/v1/wa/get_wa_templates/${userId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response.ok) {
                    const data = await response.json();
                    setTemplates(data.templates || []);
                    // Extract unique categories from templates
                    const uniqueCategories = [
                        ...new Set(data.templates.map(template => template.wa_template_category)),
                    ];
                    setCategories(uniqueCategories); // Set unique categories for filter
                } else {
                    console.error("Error fetching templates:", response.statusText);
                    setError(`Failed to fetch templates: ${response.statusText}`);
                }
            } catch (error) {
                console.error("Error fetching templates:", error);
                setError("Failed to fetch templates");
            } finally {
                setLoading(false);
            }
        };

        fetchTemplates();
    }, []);

    useEffect(() => {
        // Filter templates whenever search term or category changes
        const filtered = templates.filter(template => {
            const matchesSearch = template.name.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesCategory = selectedCategory ? template.wa_template_category === selectedCategory : true;
            return matchesSearch && matchesCategory;
        });
        setFilteredTemplates(filtered);
    }, [searchTerm, selectedCategory, templates]);

    if (loading) {
        return <div>Loading templates...</div>;
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return (
        <>
            <h4 style={{ marginTop: '60px', color: '#00417d', position:'sticky' }}>IISMS-Manager <span> > </span> All Templates</h4>
            <div className="box searfil">
                <input
                    type="text"
                    placeholder="Search templates..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-bar"
                />
                <select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    className="category-filter"
                >
                    <option value="">All Categories</option>
                    {categories.map((category, index) => (
                        <option key={index} value={category}>
                            {category}
                        </option>
                    ))}
                </select>
            </div>

        <div className="template-previews-container">
            {/* Search and Category Filter */}
           
            {/* Template Cards */}
          
                {filteredTemplates.length > 0 ? (
                    filteredTemplates.map((template) => (
                        <div key={template.id} className="">
                            <ChatPreview template={template} />
                        </div>
                    ))
                ) : (
                    <p>No templates available</p>
                )}
            
        </div>
        </>
    );
};

export default TemplatePreviews;
