import React from 'react';
import { FaBold, FaItalic, FaSmile, FaPlusCircle, FaInfoCircle, FaMinusCircle } from 'react-icons/fa';
import './NextComponent.css';
const BodySection = ({
    bodyText,
    onBodyTextChange,
    handleAddVariable,
    variableFields,
    handleVariableChange,
    handleDeleteVariable,
    handleAction
}) => {
    return (
        <div>
            <p className='text11'>Body</p>
            <div className="body-section">
                <textarea
                    className="body-textarea"
                    maxLength={1024}
                    placeholder="Enter your message..."
                    value={bodyText}
                    onChange={onBodyTextChange}
                    required
                />
                <div className="bieavi">
                    <div className="char-count">Characters: {bodyText.length} / 1024</div>
                    <div className="toolbar">
                        <button className="toolbar-button" onClick={() => handleAction('Bold')}>
                            <FaBold />
                        </button>
                        <button className="toolbar-button" onClick={() => handleAction('Italic')}>
                            <FaItalic />
                        </button>
                        <button className="toolbar-button" onClick={() => handleAction('Emoji')}>
                            <FaSmile />
                        </button>
                        <button className="toolbar-button" onClick={handleAddVariable}>
                            <FaPlusCircle /> <span> Add Variable </span>
                        </button>
                        <button className="toolbar-button" onClick={() => handleAction('Info')}>
                            <FaInfoCircle />
                        </button>
                    </div>
                </div>
            </div>

            {/* Render input fields for variables */}
            <div className="variables-container">
                {variableFields.map((variable) => (
                    <div key={variable.index} className="variable-input">
                        <span>{`{{${variable.index}}}`}</span>
                        <input
                            type="text"
                            placeholder={`Enter value for {{${variable.index}}}`}
                            value={variable.value}
                            onChange={(e) => handleVariableChange(variable.index, e.target.value)}
                        />
                        <button
                            className="delete-variable-button"
                            onClick={() => handleDeleteVariable(variable.index)}
                        >
                            <FaMinusCircle />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BodySection;
