// src/apiService.js
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

// Base URL for all API requests
const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || 'https://api.lohith.in:3007/api/v1', // Use environment variable for base URL
    headers: {
        'Content-Type': 'application/json',
    },
});

// Function to get the token from localStorage and decode it
const getUserIdFromToken = () => {
    const token = localStorage.getItem('token');
    if (token) {
        const decodedToken = jwtDecode(token);
        return decodedToken.id;
    }
    return null;
};

// Function to fetch templates
export const fetchTemplates = async () => {
    const userId = getUserIdFromToken();
    const token = localStorage.getItem('token');
    if (userId && token) {
        try {
            const response = await api.get(`/templates/get-templates/${userId}?page=1&limit=10`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            return response.data.templates || [];
        } catch (error) {
            handleApiError(error);  // Generic error handling
        }
    } else {
        throw new Error('User not authenticated');
    }
};

// Function to create a new template
export const createTemplate = async (templateName, templateDescription) => {
    const userId = getUserIdFromToken();
    const token = localStorage.getItem('token');
    if (userId && token) {
        try {
            const response = await api.post(
                '/templates/create-template',
                {
                    name: templateName,
                    description: templateDescription,
                    created_by: userId,
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            return response.data.template;
        } catch (error) {
            handleApiError(error);  // Generic error handling
        }
    } else {
        throw new Error('User not authenticated');
    }
};

// Handle API errors globally
const handleApiError = (error) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error(`Error: ${error.response.status} - ${error.response.data.message}`);
        if (error.response.status === 401 || error.response.status === 403) {
            // Handle unauthorized errors (e.g., redirect to login)
            window.location.href = '/login';
        }
    } else if (error.request) {
        // The request was made but no response was received
        console.error('Network error: No response from server');
    } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error', error.message);
    }
};

// Upload media function
export const uploadMedia = (file) => {
    const formData = new FormData();
    formData.append('mediaFile', file);

    return axios.post('https://api.lohith.in:3007/api/v1/wa/send_uploadMedia', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
    }).catch(handleApiError);
};

// Send message function
export const sendMessage = (payload) => {
    return axios.post('https://api.lohith.in:3007/api/v1/webhook/send-message', payload, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
    }).catch(handleApiError);
};
