import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaImage, FaBold, FaItalic, FaSmile, FaPlusCircle, FaInfoCircle, FaVideo, FaFileAlt } from 'react-icons/fa';
import axios from 'axios';
import './HeaderSection.css';

const HeaderSection = ({
    selectedOption,
    headerText,
    onOptionChange,
    onHeaderTextChange,
    onFileChange,
    file,
    onVariableAdd,
    onVariableRemove,
    onVariableChange: handleHeaderVariableChange,
    variableAdded,
    variableValue,
    onImageUploadSuccess,
    onVideoUploadSuccess,
    onDocumentUploadSuccess,
    onLocationSelect
}) => {
    const [showMessage, setShowMessage] = useState(false);

    const addVariable = () => {
        if (!variableAdded) {
            onHeaderTextChange({ target: { value: `${headerText} {{1}}` } });
            onVariableAdd();
            setShowMessage(false);
        } else {
            setShowMessage(true);
        }
    };

    const removeVariable = () => {
        onHeaderTextChange({ target: { value: headerText.replace(' {{1}}', '') } });
        onVariableRemove();
        setShowMessage(false);
    };

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const validTypes = {
                image: ['image/jpeg', 'image/png', 'image/gif'],
                video: ['video/mp4', 'video/avi'],
                document: ['application/pdf']
            };

            let isValidFile = false;
            switch (selectedOption) {
                case 'Image':
                    isValidFile = validTypes.image.includes(selectedFile.type);
                    break;
                case 'Video':
                    isValidFile = validTypes.video.includes(selectedFile.type);
                    break;
                case 'Document':
                    isValidFile = validTypes.document.includes(selectedFile.type);
                    break;
                default:
                    isValidFile = false;
            }

            if (isValidFile) {
                const fileUrl = URL.createObjectURL(selectedFile);
                onFileChange(fileUrl); // Pass the file URL to the parent component

                await uploadFile(selectedFile); // Upload the file after passing the URL
            } else {
                alert('Please upload a valid file (image, video, or document).');
            }
        }
    };

    const uploadFile = async (file) => {
        const formData = new FormData();
        formData.append('mediaFile', file); // Use generic "file" since it could be any type (image, video, document)

        let accessToken = localStorage.getItem('token');

        try {
            const response = await axios.post('https://api.lohith.in:3007/api/v1/wa/create_uploadMedia', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });

            console.log('Upload Response:', response);

            // Call the appropriate handler based on the file type
            switch (selectedOption) {
                case 'Image':
                    onImageUploadSuccess(response.data);  // Pass image response to parent
                    break;
                case 'Video':
                    onVideoUploadSuccess(response.data);  // Pass video response to parent
                    break;
                case 'Document':
                    onDocumentUploadSuccess(response.data);  // Pass document response to parent
                    break;
                default:
                    console.error('Unknown file type selected.');
            }
        } catch (error) {
            console.error('Upload Error:', error);
        }
    };

    const handleLocationSelect = () => {
        // Replace this with the actual location data you want to share
        const locationData = {
            latitude: '12.9716',
            longitude: '77.5946'
        };
        onLocationSelect(locationData);
    };

    return (
        <div className="header-container">
            <p className="text11">
                Header <span className="optional">• Optional</span>
            </p>
            <div className="header-section">
                <select
                    value={selectedOption}
                    onChange={onOptionChange}
                    className="header-dropdown"
                >
                    <option value="None">None</option>
                    <option value="Image">Image</option>
                    <option value="Video">Video</option>
                    <option value="Document">Document</option>
                    <option value="Text">Text</option>
                    <option value="Location">Location</option> {/* New Location option */}
                </select>

                {selectedOption === 'Text' && (
                    <>
                        <input
                            type="text"
                            placeholder="Enter header text..."
                            className="header-input"
                            value={headerText}
                            maxLength={50}
                            onChange={onHeaderTextChange}
                        />
                        <div className="toolbar-button-group">
                            <button className="toolbar-button" aria-label="Bold text">
                                <FaBold />
                            </button>
                            <button className="toolbar-button" aria-label="Info">
                                <FaInfoCircle />
                            </button>
                            <button className="toolbar-button" aria-label="Italic text">
                                <FaItalic />
                            </button>
                            <button className="toolbar-button" aria-label="Emoji">
                                <FaSmile />
                            </button>
                            <button
                                className="toolbar-button"
                                onClick={addVariable}
                                disabled={variableAdded}
                                aria-label="Add variable"
                            >
                                <FaPlusCircle /> Add Variable
                            </button>
                        </div>

                        {variableAdded && (
                            <div className="variable-input-group">
                                <span className="variable-label">{`{{1}}`}</span>
                                <input
                                    type="text"
                                    className="variable-input"
                                    placeholder="Enter value"
                                    value={variableValue}
                                    onChange={(e) => handleHeaderVariableChange(e.target.value)}
                                />
                                <button
                                    className="remove-variable-button"
                                    onClick={removeVariable}
                                    aria-label="Remove variable"
                                >
                                    Remove
                                </button>
                            </div>
                        )}

                        {showMessage && (
                            <p className="warning-message">You can add only once.</p>
                        )}
                    </>
                )}

                {(selectedOption === 'Image' || selectedOption === 'Video' || selectedOption === 'Document') && (
                    <button
                        className="upload-button"
                        onClick={() => document.getElementById('fileInput').click()}
                        aria-label={`Upload ${selectedOption.toLowerCase()}`}
                    >
                        {selectedOption === 'Image' && <FaImage />}
                        {selectedOption === 'Video' && <FaVideo />}
                        {selectedOption === 'Document' && <FaFileAlt />}
                        {` Upload ${selectedOption}`}
                    </button>
                )}

                {selectedOption === 'Location' && (
                    <button
                        className="location-button"
                        onClick={handleLocationSelect}
                        aria-label="Share location"
                    >
                        Share Location
                    </button>
                )}

                <input
                    id="fileInput"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    aria-label="Upload file"
                />

                {file && <p className="file-name">Selected File: {file.name}</p>}
            </div>
        </div>
    );
};

HeaderSection.propTypes = {
    selectedOption: PropTypes.string.isRequired,
    headerText: PropTypes.string.isRequired,
    onOptionChange: PropTypes.func.isRequired,
    onHeaderTextChange: PropTypes.func.isRequired,
    onFileChange: PropTypes.func.isRequired,
    file: PropTypes.object,
    onVariableAdd: PropTypes.func.isRequired,
    onVariableRemove: PropTypes.func.isRequired,
    onVariableChange: PropTypes.func.isRequired,
    variableAdded: PropTypes.bool.isRequired,
    variableValue: PropTypes.string.isRequired,
    onImageUploadSuccess: PropTypes.func.isRequired,
    onVideoUploadSuccess: PropTypes.func.isRequired,
    onDocumentUploadSuccess: PropTypes.func.isRequired,
    onLocationSelect: PropTypes.func.isRequired, // Added prop for location select
};

export default HeaderSection;
