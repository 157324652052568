import React from "react";
import "./TemplateCard.css";

const TemplateCard = ({ template }) => {
    // Parse the `code` JSON string into an object
    const { name, components } = JSON.parse(template.code);

    const headerComponent = components.find(comp => comp.type === "HEADER");
    const bodyComponent = components.find(comp => comp.type === "BODY");
    const footerComponent = components.find(comp => comp.type === "FOOTER");
    const buttonComponent = components.find(comp => comp.type === "BUTTONS");

    const headerText = headerComponent ? headerComponent.text : null;
    const bodyText = bodyComponent ? bodyComponent.text : null;
    const footerText = footerComponent ? footerComponent.text : null;
    const buttons = buttonComponent ? buttonComponent.buttons : [];

    return (
        <div className="template-card">
        

            <div className="box chadiv">
                <div className="chatboxmod">
                    <div className="chat-headermod">{headerText}</div>
                    <div className="chat-bodymod">{bodyText}</div>
                    <div className="chat-footermod">{footerText}</div>
                </div>
                <div className="chat-buttons12mod">
                    {buttons.map((button, index) => (
                        <button key={index} className="chat-button1mod">
                            {button.text}
                        </button>
                    ))}
                </div>
            </div>
            <div className="template-name">
                <h3>{name}</h3>
                <p>Category: {template.wa_template_category}</p>
                <p>Status: {template.wa_template_status}</p>
            </div>
        </div>
    );
};

export default TemplateCard;
