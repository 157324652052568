import React from 'react';

import TemplateList from '../TemplateList/TemplateList';


const Summary = React.memo(() => {
   
 
   

    return (
    
        <div  className='con1'>
         
            <TemplateList />

        </div>
    );
});

export default Summary;
